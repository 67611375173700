:root {
  --primary-color: #0f2870;
  --primary-color--lighter: #e9efff;
  --accent-color: rgb(239, 88, 79);
  --overlay-color: rgba(0, 0, 0, 0.6);
  --status-color--green: #22c55e;
  --status-color--yellow: #eab308;
  --status-color--red: #ef4444;
  --status-color--blue: #3b82f6;
  --status-color--gray: #6b7280;
  --title-font-color: #000044;
  --base-font-color: rgba(0, 0, 0, 0.8);
  --disabled-font-color: lightgray;
  --border-radius: 0.2rem;
  --border-radius--button: 0.5rem;
  --box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.5);
  --font-size: 1rem;
  --font-size--large: 1.5rem;
  --font-size--xl: 2rem;
  --font-size--small: 0.9rem;
  --font-size--xs: 0.5rem;
  --font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

* {
  font-family: var(--font-family);
}

.h1 {
  color: var(--title-font-color);
  font-size: var(--font-size--xl);
  font-weight: bold;
  margin: 1rem 0;
}

.h2 {
  color: var(--base-font-color);
  font-size: var(--font-size--large);
  font-weight: bold;
  margin: 0.8rem 0;
}

h3 {
  color: var(--title-font-color);
  font-size: var(--font-size);
  font-weight: bold;
  margin: 0.6rem 0;
}

.fieldset {
  display: flex;
  gap: 0.4rem;
  padding: 0;
  margin: 0.5rem 0;
  border: none;
}

.fieldset--vertical {
  flex-direction: column;
}

.label {
  display: inline-block;
}

.label:has(> .radio) {
  cursor: pointer;
}

.label > * {
  margin-left: 0.2rem;
}

.label--vertical > * {
  display: block;
}

.select {
  background-color: white;
  border: none;
  cursor: pointer;
  box-shadow: var(--box-shadow);
  padding: 0.4rem;
  border-radius: var(--border-radius);
}

.input {
  padding: 0.4rem;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  border: none;
}

.radio {
  cursor: pointer;
  width: 1.15rem;
  height: 1.15rem;
  transform: translateY(0.05rem);
}

.button {
  --bgColor: var(--primary-color);
  --color: white;
  display: flex;
  align-items: center;
  gap: 0.4rem;
  padding: 0.4rem 0.6rem;
  background-color: var(--bgColor);
  color: var(--color);
  font-size: 0.9rem;
  font-weight: bold;
  border: none;
  border-radius: var(--border-radius--button);
  box-shadow: var(--box-shadow);
  cursor: pointer;
  text-transform: uppercase;
}

.button--action {
  padding: 0.25rem;
}

.button--danger {
  --bgColor: var(--status-color--red);
}

.button--warning {
  --bgColor: var(--status-color--yellow);
}

.button--icon {
  --bgColor: transparent;
  padding: 0.5rem;
  border-radius: 50%;
  box-shadow: none;
}

.button:not([disabled]):hover {
  opacity: 0.9;
}

.button:disabled {
  cursor: default;
  background-color: var(--disabled-font-color);
}

.ripple {
  background-position: center;
  transition: background 500ms ease-out;
}

.ripple:hover {
  background-color: var(--bgColor);
  background-image: radial-gradient(circle, transparent 1%, var(--bgColor) 1%);
  background-size: 15000%;
}

.ripple:active {
  transition: background 0s;
  background-color: rgba(255, 255, 255, 0.4);
  background-size: 1000%;
}

[data-tooltip] {
  position: relative;
}

[data-tooltip]:not(a, button, input, div) {
  border-bottom: 1px dotted;
  text-decoration: none;
  cursor: help;
}

[data-tooltip]::before {
  --scale: 0;
  content: attr(data-tooltip);
  position: absolute;
  top: -0.25rem;
  left: 100%;
  color: white;
  font-size: var(--font-size--small);
  font-weight: bold;
  text-transform: none;
  text-align: justify;
  max-width: 12rem;
  max-height: 12rem;
  padding: 0.4rem 0.8rem;
  border-radius: var(--border-radius);
  border-bottom-left-radius: 0;
  background-color: var(--base-font-color);
  overflow-y: auto;
  transform: translateY(-100%) scale(var(--scale));
  transform-origin: bottom left;
  transition: 300ms transform;
  z-index: 99;
}

[data-tooltip]:hover::before {
  --scale: 1;
}

.separator {
  flex-shrink: 0;
  border-width: 0 0 thin;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.15);
}

.flex-1 {
  flex: 1;
}

@media screen and (min-width: 900px) {
  .mobile-only {
    display: none;
  }
}
