.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--overlay-color);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.spinner {
  width: 5rem;
  height: 5rem;
  border: 0.6rem solid #fff;
  border-bottom-color: var(--accent-color);
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 800ms linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
