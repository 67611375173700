.status {
  display: inline-block;
  width: 1.6rem;
  height: 1.6rem;
  box-shadow: var(--box-shadow);
  border-radius: 50%;
}

.status.green {
  background-color: var(--status-color--green);
}

.status.yellow {
  background-color: var(--status-color--yellow);
}

.status.red {
  background-color: var(--status-color--red);
}

.status.blue {
  background-color: var(--status-color--blue);
}

.status.gray {
  background-color: var(--status-color--gray);
}
