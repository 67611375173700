.navbar-item__container {
  background-color: transparent;
  border-radius: var(--border-radius--button);
}

.navbar-item__container.active {
  background-color: rgba(0, 0, 68, 0.8);
}

.navbar-item__header {
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 0.8rem 1rem;
  color: white;
  width: 100%;
  border: none;
  border-radius: var(--border-radius--button);
  background-color: transparent;
  cursor: pointer;
}

.navbar-item__container.active .navbar-item__header {
  color: var(--accent-color);
}

.navbar-item {
  color: white;
  text-decoration: none;
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 0.8rem 1rem;
  border-radius: var(--border-radius--button);
  font-size: 1rem;
  background-color: transparent;
}

.navbar-item.active {
  color: var(--accent-color);
  background-color: rgba(0, 0, 68, 0.45);
}

.navbar-item--child {
  padding-left: 3.4rem;
  opacity: 0.6;
}

.navbar-item--child:hover,
.navbar-item--child.active {
  opacity: 1;
}

.navbar-item__icon {
  font-size: 1.5rem;
}

.navbar-item__icon--animated {
  transition: transform 100ms ease-in;
}

.navbar-item__container.open .navbar-item__icon--animated {
  transform: rotate(90deg);
}

.navbar-item__label {
  font-size: 1rem;
  font-weight: bold;
}

.navbar-item__children {
  max-height: 0;
  overflow: hidden;
  transition: max-height 100ms linear;
}

.navbar-item__container.open .navbar-item__children {
  max-height: 10rem;
}
