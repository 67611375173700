.tabs {
  display: inline-flex;
  background-color: var(--primary-color--lighter);
  border-radius: var(--border-radius);
  overflow-y: hidden;
  overflow-x: auto;
}

.tab {
  padding: 0.6rem 1.2rem;
  color: var(--title-font-color);
  font-size: 1rem;
  background-color: transparent;
  cursor: pointer;
  transition: 100ms ease-in;
}

.tab.active {
  font-weight: bold;
  color: white;
  background-color: var(--primary-color);
}
