.toolbar {
  display: flex;
  gap: 1rem;
  align-items: center;
  box-shadow: 0 0 0.2rem 0 rgba(0, 0, 0, 0.6);
  padding: 0.5rem;
  background-color: var(--primary-color);
  min-height: 3.5rem;
}

@media screen and (min-width: 900px) {
  .toolbar {
    background-color: white;
  }
}
