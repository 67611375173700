.table__container {
  overflow: auto;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
}

.table {
  min-width: 40rem;
  position: sticky;
  width: 100%;
  background-color: white;
  border-collapse: collapse;
  font-size: var(--font-size--small);
  text-align: center;
  table-layout: fixed;
}

.table thead tr {
  background-color: var(--primary-color);
  color: white;
  font-weight: bold;
  text-align: center;
}

.table thead tr,
.table tbody tr {
  border-bottom: 1px solid lightgray;
}

.table tbody tr:nth-of-type(even) {
  background-color: var(--primary-color--lighter);
}

.table th,
.table td {
  padding: 1rem 1.2rem;
}

.table th.sortable {
  cursor: pointer;
  user-select: none;
}

.table__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.4rem 0;
}

.table__pagination {
  font-size: var(--font-size--small);
}

.table__current-page {
  margin: 0 0.2rem;
}

.table__controls {
  display: inline-block;
}

.table__button {
  vertical-align: middle;
  cursor: pointer;
  background-color: transparent;
  border: none;
  border-radius: var(--border-radius);
  color: var(--primary-color);
}

.table__button:not([disabled]):hover {
  background-color: var(--primary-color--lighter);
}

.table__button:disabled {
  cursor: default;
  color: var(--disabled-font-color);
}

.table__filter {
  align-self: flex-start;
  margin-bottom: 1rem;
}

.table__pagination {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}

.table__placeholder {
  color: var(--base-font-color);
  opacity: 0.8;
  cursor: default;
  user-select: none;
}

.table__placeholder p {
  margin: 0;
}

.table__placeholder p span {
  font-size: 3rem;
}

@media screen and (min-width: 600px) {
  .table__pagination {
    display: block;
  }
}
