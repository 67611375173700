.navbar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 99999;
  padding: 0 1rem;
  box-shadow: var(--box-shadow);
  background-color: var(--primary-color);
  transform-origin: 0% 0%;
  transform: translate(-100%, 0);
  transition: transform 300ms cubic-bezier(0.77, 0.2, 0.05, 1);
}

.navbar.open {
  transform: none;
}

.navbar.open + .navbar__overlay {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: var(--overlay-color);
  z-index: 9999;
}

.navbar__header {
  display: flex;
  justify-content: center;
  padding-top: 1rem;
}

.navbar__logo {
  width: 10rem;
}

.navbar__items {
  list-style: none;
  padding: 0;
}

.navbar__item + .navbar__item {
  margin-top: 0.5rem;
}

@media screen and (min-width: 900px) {
  .navbar {
    transform: none;
  }

  .navbar.open + .navbar__overlay {
    display: none;
  }
}
